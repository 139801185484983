.title-padding {
  padding: 0.5em 0.5em 0 1em;
}

.subtitle-padding {
  padding: 0.5em 1.5em;
}

.title-secondary-padding {
  padding: 0.5em 0;
}

.button-padding {
  padding: 1.5rem;
}

.button-secondary-padding {
  padding: 1rem;
}

.inline-block {
  display: inline-block;
}

.green-success-status {
  --color: #538000;
}