.document-upload-page-card-padding {
  padding: 0 2em;
  margin: 10px 0 20px 0;
}

.document-upload-unit-padding {
  padding: 1em;
}

.document-upload-spinner {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.1);
}

.document-upload-page-spinner-background {
  filter: blur(2px);
}
