.document-upload-timestamp-status {
  padding: 0 3rem;
}
.document-upload-timestamp {
  padding: 0 0.25rem;
}

.document-upload-component-padding {
  padding: 1em 0;
}

.attachment-error-message {
  padding: 0.5em 0;
  color: #cc0c39;
}
